import React from 'react';
import Helmet from 'react-helmet';

import PropTypes from 'prop-types';

import config from 'utils/siteConfig';

import { useStaticQuery, graphql } from "gatsby"

const StructuredVideo = ({
  videoId
}) => {
  const data = useStaticQuery(graphql`
    query IndexQuery {
      allYoutubeVideo {
        nodes {
          id
          title
          description
          contentDetails {
            duration
          }
          publishedAt
        }
      }
    }
  `)

  const item = data.allYoutubeVideo.nodes.find((n) => n.id == videoId);
  if (!item) {
    return null;
  }

  // https://developers.google.com/search/docs/data-types/video#video-object
  const schema = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": item.title,
    "description": item.description,
    "embedUrl": `https://www.youtube.com/embed/${videoId}`,
    "thumbnailUrl": [
      `https://img.youtube.com/vi/${videoId}/0.jpg`,
      `https://img.youtube.com/vi/${videoId}/1.jpg`,
      `https://img.youtube.com/vi/${videoId}/2.jpg`,
      `https://img.youtube.com/vi/${videoId}/3.jpg`,
    ],
    "uploadDate": item.publishedAt,
    "duration": item.contentDetails.duration
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

StructuredVideo.propTypes = {
  videoId: PropTypes.string.isRequired
};

export default StructuredVideo;
