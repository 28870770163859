import React from 'react';

import VideoThumbnail from 'components/common/VideoThumbnail';

const video = {
  filename: 'videos/thumbnails/comment-consulter-mia-co.png',
  alt: '',
  video: 'https://www.youtube.com/embed/FlgazPKd1BI',
};
const VideoHowItWorks = () => <VideoThumbnail data={video} showPlayIcon />;

export default VideoHowItWorks;
