import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Image from 'components/common/utils/Image';
import VideoPlayer from 'components/common/VideoPlayer';
import StructuredVideo from 'components/common/meta/StructuredVideo';

import PlayGrey from "images/icons/play-grey.svg";

import "./styles.sass";

class VideoThumbnail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleShow = () => {
    this.setState({ showModal: true });
  };

  render() {
    const { data, showPlayIcon } = this.props;
    return (
      <>
        <StructuredVideo {...data.youtube} />
        <div className="video-thumbnail grow-card" onClick={this.handleShow}>
          {data.filename ? (
            <Image filename={data.filename} alt={data.alt} />
          ) : (
            data.extFilename && (
              <img
                className="img-fluid"
                src={data.extFilename}
                alt={data.alt}
              />
            )
          )}
          {showPlayIcon && (
            <div className="video-thumbnail-overlay">
              <PlayGrey width="68" />
            </div>
          )}
        </div>
        <Modal
          centered
          show={this.state.showModal}
          onHide={this.handleClose}
          size="xl"
        >
          <div className="expert-video-modal">
            <VideoPlayer video={data.video} />
          </div>
        </Modal>
      </>
    );
  }
}

VideoThumbnail.defaultProps = {
  showPlayIcon: false,
};

export default VideoThumbnail;
