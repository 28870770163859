import React from 'react';

const VideoPlayer = (props) => (
  <figure className="kg-card kg-embed-card" style={{ margin: '0' }}>
    <iframe
      width="480"
      height="270"
      src={props.video}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen=""
    />
  </figure>
);

export default VideoPlayer;
