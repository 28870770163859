import React, { useState } from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';

import Container from 'react-bootstrap/Container';
import { Layout } from 'components/common';
import TextSectionList from 'components/common/TextSectionList';
import Section from 'components/common/Section';
import Image from 'components/common/utils/Image';
import Questionaire from 'images/Questionaire.svg';
import Ordonnance from 'images/Ordonnance.svg';
import Consultation from 'images/consultation.svg';

import StyledLink from 'components/common/utils/StyledLink';

import VideoHowItWorks from 'components/common/VideoHowItWorks';

import { sections } from '../Landing/data';

import './styles.sass';

import TherapistCarousel from '../common/TherapistsCarousel';

const IndexPage = () => {
  const [open, setOpen] = useState(false);
  return (
    <Layout
      footerCtaPath="/consulter/"
      showLinksInFooter={false}
      backgroundBeforeFooter="background-color-pale-yellow"
      showNewsletterBeforeFooter={false}
    >
      <CharlesMeta
        data={{
          title: 'Comment ça marche ? Réservez une téléconsultation sur Mia.co',
          description:
            "Comment téléconsulter une sexologue sur Mia.co ? Combien coûte une téléconsultation ? Qui sont les sexologues ? Est-ce remboursé par la sécurité sociale ?",
        }}
      />
      <div className="text-left landing-page">
        <Section className="text-white d-flex flex-column background-color-pale-yellow home-top-section patho-top-section m-lg-0">
          <div className="desktop-home-top-div d-flex flex-column flex-lg-row align-items-center desktop-hiw-top-div justify-content-center justify-content-lg-start">
            <div className="home-top-left home-top-left-hiw">
              <div>
                <h1 className="h1-hiw-top-left">
                  Comment ça marche&nbsp;?
                </h1>
                <div>
                  <div className="d-flex hiw-bullet-div">
                    <span className="hiw-bullet-number">1.</span>
                    <div className="d-flex flex-column">
                      <span className="hiw-bullet-title">Remplissez le questionnaire</span>
                      <span className="hiw-bullet-desc">
                        Renseignez votre profil médical, consultable uniquement par votre sexologue.
                      </span>
                    </div>
                  </div>
                  <div className="d-flex hiw-bullet-div">
                    <span className="hiw-bullet-number">2.</span>
                    <div className="d-flex flex-column">
                      <span className="hiw-bullet-title">Consultez en ligne (45€)</span>
                      <span className="hiw-bullet-desc">
                        Parlez à votre sexologue (téléphone, vidéo ou messages écrits) de vos
                        symptômes et obtenez un protocole de traitement.
                      </span>
                    </div>
                  </div>
                  <div className="d-flex hiw-bullet-div">
                    <span className="hiw-bullet-number">3.</span>
                    <div className="d-flex flex-column">
                      <span className="hiw-bullet-title">Bénéficiez d'un suivi intégral de A à Z</span>
                      <span className="hiw-bullet-desc">
                        Recevez un traitement si besoin, bénéficiez du suivi de votre sexologue
                        ainsi que d'une hotline 5 jours sur 7.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex mb-4 mt-4 justify-content-center justify-content-lg-start">
                  <StyledLink
                    variant="orange"
                    path="/consulter/"
                    className=""
                  >
                    CONSULTER
                  </StyledLink>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column desktop-videoHIW justify-content-lg-center align-items-lg-center">
              <VideoHowItWorks className="" />
              <span className="video-hiw-text mx-0">
                Je suis Camille Bataillon, la sexologue qui a créé ce protocole de
                téléconsultation.
              </span>
            </div>
          </div>
        </Section>
        <Section className="hiw-section-hiw-page">
          <Container className="d-flex flex-column">
            <div className="d-flex justify-content-around hiw-section-row">
              <div className="hiw-section-div">
                <div className="hiw-section-text-div">
                  <h4 className="hiw-section-title">
                    1. Remplissez le questionnaire
                  </h4>
                  <span className="hiw-section-sub-title">
                    Pour personnaliser votre consultation au maximum
                  </span>
                  <span className="hiw-section-text">
                    Ce questionnaire est 100% confidentiel et uniquement à destination de votre
                    sexologue pour bien comprendre vos symptômes et vos objectifs.
                  </span>
                </div>
              </div>
              <Questionaire height={200} width={165} />
            </div>
            <div className="d-flex justify-content-around hiw-section-row">
              <Consultation width={350} height={183} />
              <div className="hiw-section-div">
                <div className="hiw-section-text-div second-text-div">
                  <h4 className="hiw-section-title">
                    2. Réalisez votre/vos rendez-vous en ligne (≃ 30 min)
                  </h4>
                  <span className="hiw-section-sub-title">
                    Simple, complet, depuis chez vous
                  </span>
                  <span className="hiw-section-text">
                    Avec votre sexologue, obtiendrez des solutions et un protocole d'accompagnement
                    personnalisé.
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-around hiw-section-row">
              <div className="hiw-section-div">
                <div className="hiw-section-text-div">
                  <h4 className="hiw-section-title">
                    3. Bénéficiez d'un suivi intégral de A à Z
                  </h4>
                  <span className="hiw-section-sub-title">
                    Un accompagnement complet
                  </span>
                  <span className="hiw-section-text">
                    Recevez un traitement, des produits si besoin et bénéficiez du suivi de votre
                    sexologue ainsi que d’une hotline 5 jours sur 7.
                  </span>
                </div>
              </div>
              <Ordonnance height={200} width={165} />
            </div>
          </Container>
        </Section>
        <Section className="d-flex flex-column therapist-carousel-section">
          <div className="d-flex flex-column therapist-div-wrapper">
            <h2 className="h2-title text-white text-center">DES EXPERTES POUR VOUS ACCOMPAGNER</h2>
            <TherapistCarousel />
          </div>
        </Section>
        <Section className="d-flex flex-column align-items-center faq-section">
          <Container className="d-flex flex-column align-items-center">
            <h3 className="section-title text-center">
              FAQ
            </h3>
            <TextSectionList sections={sections} />
          </Container>
        </Section>
        <Section className="two-womens-section">
          <StyledLink
            variant="orange"
            path="/consulter/"
            className=""
            margin
          >
            CONSULTER
          </StyledLink>
        </Section>
      </div>
    </Layout>
  );
};

export default IndexPage;
